import { gql } from "@apollo/client"

const ZipInfoQuery = gql`
  query ZipInfo($zip: String!) {
    zipInfos(where: { zip: $zip }) {
        id
        highlights
        metro
    }
  }
`

export default ZipInfoQuery
