import React, { useState, useEffect } from "react"

import { client } from "../../apollo/client"
import ZipInfoQuery from "../../apollo/queries/zipInfo"

const NeighbourhoodReview = ({ zip }) => {
  const [zipInfo, setZipInfo] = useState({})
  useEffect(() => {
    const getData = async () => {
      const { data } = await client.query({
        query: ZipInfoQuery,
        variables: { zip: `${zip}` },
      })

      const { zipInfos } = data
      setZipInfo(zipInfos[0])
    }

    getData()
  }, [zip])

  return (
    <div className="review-text">{zipInfo ? zipInfo.highlights : ""}. </div>
  )
}

export default NeighbourhoodReview
