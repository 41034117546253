import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import { Card, CardBody, CardImg } from "reactstrap"
import FeatherIcon from "feather-icons-react"

import Layout from "../../components/layout"
import { ProjectionGraph } from "../../components/shared"
import PageBreadcrumb from "../../components/shared/PageBreadcrumb"

import NeighbourhoodReview from "../../components/opportunities/NeighbourhoodReview"
import { currencyFormatter } from "../../utils"
import {
  createPropertyAssumptions,
  getCashFlowDetails,
} from "../../utils/assumptionUtils"
// import { client } from "../../apollo/client"
// import OpportunityDetailsQuery from "../../apollo/queries/OpportunityDetails"
// import CardImage from "../../assets/images/card-image.png"
import CarImage from "../../assets/images/car-icon.png"
import BathImage from "../../assets/images/bath-icon.png"
import CalendarImage from "../../assets/images/calender-icon.png"
import ChatImage from "../../assets/images/chat-icon.png"
import SqftImage from "../../assets/images/sqft-icon.png"
import PriceImage from "../../assets/images/price-icon.png"
import RentImage from "../../assets/images/rent-icon.png"
import StarImage from "../../assets/images/star-icon.png"

export const query = graphql`
  query OpportunityQuery($slug: String!) {
    strapiGlobal {
      siteName
      favicon {
        localFile {
          publicURL
        }
      }
      defaultSeo {
        robots
        metaDescription
        metaTitle
        keywords
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }

    strapiOpportunity(slug: { eq: $slug }) {
      title
      type
      slug
      updated_at
      description
      medias {
        url
        localFile {
          publicURL
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      property {
        after_repair_value
        baths
        hoa_fees
        beds
        insurance
        lat
        lng
        lot_size
        management_fee
        id
        market_value
        no_of_units
        occupany_rate
        off_market
        property_tax
        rent
        repair_estimate
        sale_price
        status
        type
        vacancy_rate
        year_build
        neighborhood_rating
        zip
      }
      posted_by {
        id
        name
        logoBackground
        logo {
          url
          alternativeText
        }
        slug
        userId
        verified
      }
    }
  }
`
const BASE_URL = "http://localhost:3000"

const defaultMetadata = {
  amortized_years: {
    type: "amortized_years",
    value: 30,
    display: "Number of Years",
  },
  down_payment: {
    type: "down_payment",
    value: 25,
    value_type: "percent",
    display: "Down Payment",
  },
  hoa_dues: {
    type: "hoa_dues",
    value: 0,
    value_type: "dollars",
    frequency: "monthly",
    display: "Homeowners Association",
  },
  insurance: {
    type: "insurance",
    value: 0,
    value_type: "dollars",
    frequency: "monthly",
    display: "Insurance Cost",
  },
  mortgage_interest: {
    type: "mortgage_interest",
    value: 3.8,
    value_type: "percent",
    display: "Mortgage Interest",
  },
  property_management: {
    type: "property_management",
    value: 0,
    value_type: "dollars",
    frequency: "monthly",
    display: "Property Management",
  },
  property_tax: {
    type: "property_tax",
    value: 0,
    value_type: "dollars",
    frequency: "monthly",
    display: "Property Tax",
  },
  purchase_price: {
    type: "purchase_price",
    value: 0,
    value_type: "dollars",
    display: "Purchase Price",
  },
  rent: {
    type: "rent",
    value: 0,
    frequency: "monthly",
    value_type: "dollars",
    display: "Rent",
  },
  vacancy_rate: {
    type: "vacancy_rate",
    value: 10,
    value_type: "percent",
    display: "Vacancy",
  },
}

const Opportunity = props => {
  const { strapiGlobal, strapiOpportunity } = props.data
  const [metadata, setMetadata] = useState(defaultMetadata)
  const [mainImage, setMainImage] = useState("")
  const [downPaymentPercent, setDownPaymentPercent] = useState(0)
  const [cashflow, setCashflow] = useState(0)
  const [cashROI, setCashROI] = useState(0)
  const [totalROI, setTotalROI] = useState(0)
  // const [newStrapiOpportunity, setNewStrapiOpportunity] = useState({})
  // const [isLoading, setIsLoading] = useState(true)

  // useEffect(() => {
  //   const slug = window.location.pathname.split("/")[2]
  //   const getData = async () => {
  //     const { data, loading, error } = await client.query({
  //       query: OpportunityDetailsQuery,
  //       variables: { slug: slug },
  //     })

  //     const { opportunities } = data

  //     setNewStrapiOpportunity(opportunities[0])
  //     setIsLoading(loading)
  //   }

  //   getData()
  // }, [])

  useEffect(() => {
    const getAssumptionData = async () => {
      if (strapiOpportunity) {
        const data = {
          price: currencyFormatter(strapiOpportunity.property.market_value),
          rent: strapiOpportunity.property.rent,
          insurance: currencyFormatter(strapiOpportunity.property.insurance),
          taxes: currencyFormatter(strapiOpportunity.property.property_tax),
          hoa_dues: currencyFormatter(strapiOpportunity.property.hoa_fees),
        }

        const propertyAssumptions = await createPropertyAssumptions(data)
        let { totalCashflow, totalROI, cashROI } = await getCashFlowDetails(
          propertyAssumptions
        )
        const downPaymentPercent = propertyAssumptions.down_payment.value
        setMetadata(propertyAssumptions)
        setDownPaymentPercent(downPaymentPercent)
        setCashflow(totalCashflow)
        setCashROI(cashROI)
        setTotalROI(totalROI)
      }
    }

    getAssumptionData()
    setMainImage(
      `https://cms.bettercapital.us${strapiOpportunity.medias[0].url}`
    )
  }, [strapiOpportunity])

  useEffect(() => {
    const updateAssumptions = async () => {
      let { totalCashflow, totalROI, cashROI } = await getCashFlowDetails(
        metadata
      )
      setCashflow(totalCashflow)
      setCashROI(cashROI)
      setTotalROI(totalROI)
    }

    updateAssumptions()
  }, [metadata])

  const setNewDownPayment = value => {
    setDownPaymentPercent(value)
    setAssumptionsField("down_payment", "value", value)
  }

  const setAssumptionsField = (assumption, key, value, ...restOfKeyValues) => {
    const metaObj = { ...metadata[assumption] }
    metaObj[key] = value
    let counter = 0
    while (counter < restOfKeyValues.length) {
      metaObj[restOfKeyValues[counter]] = restOfKeyValues[counter + 1]
      counter += 2
    }
    setMetadata({
      ...metadata,
      [assumption]: metaObj,
    })
  }

  const seo = {
    metaTitle: strapiOpportunity.title,
    metaDescription: strapiOpportunity.description,
    shareImage: strapiOpportunity.medias[0],
  }

  return (
    <Layout seo={seo} strapiGlobal={strapiGlobal}>
      <PageBreadcrumb
        page={{
          source: "opportunities",
          showBreadcrumb: false,
        }}
      ></PageBreadcrumb>
      {/* {isLoading && (
        <Container className="mt-100 mb-100 text-center">
          <div>Loading.....</div>
        </Container>
      )} */}
      <Container className="opportunity-container mt-100 mt-60">
        <div className="main-title">
          <h2>{strapiOpportunity.title} </h2>
          <div className="d-flex justify-content-between align-items-center sub-main-title">
            <h2
              onClick={() =>
                window.open(
                  `${BASE_URL}/opportunity/${strapiOpportunity.slug}`,
                  "_blank"
                )
              }
            >
              <FeatherIcon
                icon="bookmark"
                className="fea icon-md bookmark-icon"
              />
            </h2>
            {strapiOpportunity.property.status === "sold" ? (
              <div className="label-sold">Sold Out</div>
            ) : (
              <div className="label-available">Available</div>
            )}
          </div>
        </div>
        <Row>
          <Col md={8}>
            <div className="d-flex justify-content-center align-items-center main-image-div ">
              <div className="main-image-container">
                <img src={mainImage} alt="main_image" className="main-image" />
              </div>
              <div className="small-image  image">
                {strapiOpportunity.medias.map((media, index) =>
                  index < 4 ? (
                    <img
                      src={`https://cms.bettercapital.us${media.url}`}
                      className={
                        index > 0 ? "sub-images sub-image-margin" : "sub-images"
                      }
                      alt="sub_image"
                      onClick={() =>
                        setMainImage(`https://cms.bettercapital.us${media.url}`)
                      }
                    />
                  ) : null
                )}
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-start icons">
              <div className="d-flex justify-content-center align-items-center details">
                <img src={CarImage} alt="bedimage" />
                <span className="details-number">
                  {strapiOpportunity.property.beds}{" "}
                </span>
              </div>
              <div className="d-flex justify-content-center align-items-center details">
                <img src={BathImage} alt="bathimage" />
                <span className="details-number">
                  {" "}
                  {strapiOpportunity.property.baths}{" "}
                </span>
              </div>
              <div className="d-flex justify-content-center align-items-center details">
                <img src={SqftImage} alt="propertysizeimage" />
                <span className="details-number">
                  {strapiOpportunity.property.lot_size}
                </span>
              </div>
              <div className="d-flex justify-content-center align-items-center details">
                <img src={CalendarImage} alt="calendarimage" />
                <span className="details-number">
                  {strapiOpportunity.property.year_build}
                </span>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <Card className="blog owner rounded border-0 shadow card p-3 mt-3">
              <CardImg
                src={`https://cms.bettercapital.us${strapiOpportunity.posted_by.logo.url}`}
                top
                className="rounded-top content-card-image-01 d-flex justify-content-center"
                alt=""
              />
              <CardBody>
                <div className="d-flex justify-content-center align-items-center ">
                  <h6>{strapiOpportunity.posted_by.name}</h6>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <p>Posted 4 deals</p>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <p className="size">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut ero labore et dolore.
                  </p>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="message-button"
                    onClick={() =>
                      window.open(
                        `${BASE_URL}/chats/${strapiOpportunity.posted_by.userId}`,
                        "_blank"
                      )
                    }
                  >
                    <img src={ChatImage} alt="chatImage" />{" "}
                    &nbsp;&nbsp;&nbsp;Message
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container className="mt-5">
        <div className="text-01">
          <h4>Property Highlights</h4>
        </div>
        <div className="d-flex justify-content-between align-items-center tags mb-5 mt-3 p-5">
          <div className="tags-01">
            <div className="d-flex justify-content-center align-items-start flex-column highlight-tag">
              <div className="highlights-details">
                <img
                  src={PriceImage}
                  className="hightlight-icons"
                  alt="highlightIcon"
                />
                Price
              </div>
              <h5 className="mb-0 font-weight-bold">
                {currencyFormatter(strapiOpportunity.property.sale_price)}
              </h5>
            </div>
            <div className="d-flex justify-content-center align-items-start flex-column highlight-tag">
              <div className="highlights-details">
                <img
                  src={RentImage}
                  className="hightlight-icons"
                  alt="highlightIcon"
                />
                Occupancy{" "}
              </div>
              <h6 className="mb-0">{strapiOpportunity.property.status}</h6>
            </div>
          </div>
          <div className="tags-01">
            <div className="d-flex justify-content-center align-items-start flex-column highlight-tag">
              <div className="highlights-details">
                <img
                  src={RentImage}
                  className="hightlight-icons"
                  alt="highlightIcon"
                />
                Expected Use{" "}
              </div>
              <h6 className="mb-0">Long Term</h6>
            </div>
            <div className="d-flex justify-content-center align-items-start flex-column highlight-tag">
              <div className="highlights-details">
                <img
                  src={RentImage}
                  className="hightlight-icons"
                  alt="highlightIcon"
                />
                Rent
              </div>
              <h6 className="mb-0">
                {currencyFormatter(strapiOpportunity.property.rent)}/m
              </h6>
            </div>
          </div>
          <div className="tags-02">
            <div className="d-flex justify-content-center align-items-start flex-column highlight-tag">
              <div className="highlights-details">
                <img
                  src={RentImage}
                  className="hightlight-icons"
                  alt="highlightIcon"
                />
                Gross Yeild
              </div>
              <h6 className="mb-0">{totalROI.toFixed(2)} %</h6>
            </div>
          </div>
        </div>
      </Container>

      <Container className="mt-5 mb-5">
        <div className="text-01">
          <h4>Overview</h4>
        </div>
        <div className="paragraph">
          <p>{strapiOpportunity.description}.</p>
        </div>
      </Container>
      <Container className="mt-5 mb-5">
        <div className="text-01">
          <h4>Cashflow</h4>
        </div>
        <Row>
          <Col md={6}>
            <div className="d-flex justify-content-between align-items-center highlights-container cashflow mb-5 mt-3 py-5 px-2">
              <div className="d-flex justify-content-center align-items-start cashflow-1 flex-column bold-text">
                {currencyFormatter(cashflow)}
                <p className="highlights-details">CASHFLOW</p>
              </div>
              <div className="d-flex justify-content-center align-items-start  flex-column bold-text cashflow-1">
                {cashROI} %
                <p className="highlights-details">CASH ON CASH ROI</p>
              </div>
              <div className="d-flex justify-content-center align-items-start flex-column bold-text cashflow-1">
                {totalROI.toFixed(2)} %
                <p className="highlights-details">TOTAL ROI</p>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="d-flex justify-content-between align-items-center highlights-container mb-5 mt-3 p-5">
              <div className="progress-box cashflow-2 ">
                <h6 className="progress-title  text-dark title-dark">
                  Down Payment {downPaymentPercent}%
                </h6>
                <input
                  type="range"
                  value={downPaymentPercent}
                  min={0}
                  max={100}
                  step={1}
                  className="form-range position-relative"
                  onChange={e => setNewDownPayment(e.target.value)}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="mt-5 mb-5">
        <div className="text-01">
          <h4>Price Insights</h4>
        </div>
        <Row className="insights">
          <div className="price-insights">
            <div className="d-flex justify-content-start  align-items-start price-highlights-container tagss  mt-3 p-5">
              <div className="price-tags-01">
                <div className="d-flex justify-content-center align-items-start flex-column ">
                  <div className="highlights-details">
                    Estimated Market Value
                  </div>
                  <h6 className="mb-0">
                    {currencyFormatter(strapiOpportunity.property.market_value)}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="price-insights-01">
            <div className="d-flex justify-content-between align-items-center price-highlights-container price-tags mt-0 p-5">
              <div className="price-tags-01">
                <div className="d-flex justify-content-center align-items-start flex-column price">
                  <div className="highlights-details">Property Tax</div>
                  <h6 className="mb-0">
                    {currencyFormatter(strapiOpportunity.property.property_tax)}
                    / month
                  </h6>
                </div>
                <div className="d-flex justify-content-center align-items-start flex-column price">
                  <div className="highlights-details">Insurance Cost</div>
                  <h6 className="mb-0">
                    {currencyFormatter(strapiOpportunity.property.insurance)}/
                    month
                  </h6>
                </div>
              </div>
              <div className="price-tags-01">
                <div className="d-flex justify-content-center align-items-start flex-column price">
                  <div className="highlights-details">Mortgage interest</div>
                  <h6 className="mb-0">3.8%</h6>
                </div>
                <div className="d-flex justify-content-center align-items-start flex-column price">
                  <div className="highlights-details">No. of years</div>
                  <h6 className="mb-0">30 years</h6>
                </div>
              </div>
              <div className="price-tags-01">
                <div className="d-flex justify-content-center align-items-start flex-column price">
                  <div className="highlights-details long-text">
                    Property Management
                  </div>
                  <h6 className="mb-0">
                    {currencyFormatter(
                      strapiOpportunity.property.management_fee
                    )}{" "}
                    / month
                  </h6>
                </div>
                <div className="d-flex justify-content-center align-items-start flex-column price">
                  <div className="highlights-details">
                    Home Owner's Association
                  </div>
                  <h6 className="mb-0">
                    {currencyFormatter(strapiOpportunity.property.hoa_fees)} /
                    month
                  </h6>
                </div>
              </div>
            </div>
            <div className="price-insights-02 p-5">
              <div className="d-flex justify-content-center align-items-start flex-column price">
                <div className="highlights-details">Rent: </div>
                <h6 className="mb-0">
                  {currencyFormatter(strapiOpportunity.property.rent)} / month
                </h6>
              </div>
            </div>
          </div>
        </Row>
      </Container>

      <Container className="mt-5 mb-5">
        <Row>
          <Col sm={12} md={6}>
            <ProjectionGraph assumptions={metadata} className="graph" />
          </Col>
          <Col sm={12} md={6}>
            <ProjectionGraph assumptions={metadata} overall={false} />
          </Col>
        </Row>
      </Container>
      <Container className="mt-5 mb-5">
        <div className="text-01">
          <h4>Neighbourhood Review &nbsp;&nbsp;</h4>
          <div className="rating-star">
            <img src={StarImage} alt="starImage" />
            <div className="rating-text">
              {strapiOpportunity.property.neighborhood_rating}
            </div>
          </div>
        </div>

        <NeighbourhoodReview
          zip={
            strapiOpportunity.property.zip
              ? strapiOpportunity.property.zip
              : "00501"
          }
        />
      </Container>
    </Layout>
  )
}

export default Opportunity
